<template>
    <div class="table-components">
        <el-table
            :data="table.list"
            border
            ref="myTable"
            style="width: 100%"
            :height="table.height"
            :row-class-name="tableRowClassName"
            :row-key="rowKeyFun"
            :summary-method="getSummaries"
            :show-summary="table.is_show_summary"
            @selection-change="handleSelectionChange"
            @select="handleSelect"
            @row-click="handleRowClick"
            >
            <el-table-column v-if="table.is_index" type="index" align="center"></el-table-column>
            <el-table-column  :selectable="selectableFun" v-if="table.is_check" type="selection" width="55"></el-table-column>
            <el-table-column  :fixed="item.fixed" header-align="center" align="center" v-for="(item,index) of table.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width?item.width:null">
                <template #header="{column,$index}">
                    <slot v-if="table.is_custom_header" name="header" :column="column" :index="$index"></slot>
                    <div v-else>{{column.label}}</div>
                </template>
                <template #default="scope">
                    <slot name="default" :scope="scope" :item="item" :index="index"></slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { defineComponent, watch, ref, toRefs } from "vue"
import { toDecimal,toInteger } from '@/assets/js/utils'
import calculation from '@/assets/js/calculation'
export default defineComponent({
    name:'table-component',
    props:{
        table:{
            type:Object,
            default:{}
        }
    },
    setup(props,context) {
        let myTable = ref(null)

        watch(()=>props.table,((newVal,oldVal)=>{
            
        }))

        /**
         * 点击行触发，选中或不选中复选框
         */
        function handleRowClick(row, column, event){
            if(props.table.is_row_click){
                myTable.value.toggleRowSelection(row);
            }
        }

        function rowKeyFun(row){
            if(props.table.list.length>0){
                return row.id
            }else{
                return ''
            }
        }
        function toggleRowSelection(){
            return myTable.value.toggleRowSelection(...arguments)
        }
        function tableRowClassName({row, rowIndex}) {
            return '';
        }
        function handleSelectionChange(){
            console.log('handleSelectionChange',...arguments)
            context.emit('handleSelectionChange',...arguments)
        }

        function handleSelect(){
            console.log('handleSelect',...arguments)
            context.emit('handleSelect',...arguments)
        }

        // 合并合计第一行
        function arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                if (columnIndex === 0) {
                    return [0, 0];
                } else if (columnIndex === 1) {
                    return [1, 2];
                }
            }
        }

        function getSummaries(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }else if(props.table.summary_index && props.table.summary_index.indexOf(index) != -1){
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return toDecimal(calculation.accAdd(prev,curr),2);
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += '';
                    } else {
                        sums[index] = '';
                    }
                }
            });
            return sums;
        }

        function clearSelection(){
            return myTable.value.clearSelection(...arguments)
        }

        function selectableFun(row,index){
            if(row.disabled){
                return false
            }
            return true
        }


        return{
            table:props.table,
            myTable,
            clearSelection,
            rowKeyFun,
            toggleRowSelection,
            tableRowClassName,
            handleSelectionChange,
            selectableFun,
            getSummaries,
            handleRowClick
        }
    }
})
</script>
<style lang="scss" scoped>
.table-components{
    & /deep/ .el-table{
        .color-row-1 {
            background: rgb(224, 236, 223);
        }
        .color-row-2 {
            background: rgb(247, 225, 226);
        }
        .el-table__row{
            cursor: pointer;
        }

    }
}
</style>
