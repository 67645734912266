<template>
    <div class="order-manage-page">
        <input id="copyinput">
        <el-card class="global-box-card global-no-box-shadow">
            <template #header>
                <el-form ref="formRef" :inline="true" :model="search_config.form" label-width="90px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="门店名称" prop="shops_name">
                                <el-input v-model="search_config.form.shops_name" placeholder="门店名称" @keyup.enter="searchHandle"></el-input>
                            </el-form-item>
                            <el-form-item label="订单编号" prop="mo_sn">
                                <el-input v-model="search_config.form.mo_sn" placeholder="订单编号" @keyup.enter="searchHandle"></el-input>
                            </el-form-item>
                            <el-form-item label="订单类型" prop="mo_type">
                                <el-select v-model="search_config.form.mo_type" placeholder="请选择订单类型">
                                  <el-option
                                    v-for="item in orderTypeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="订单状态" prop="status">
                                <el-select v-model="search_config.form.status" placeholder="请选择订单状态">
                                  <el-option
                                    v-for="item in orderStatusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                  </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="客户姓名" prop="c_name">
                                <el-input v-model="search_config.form.c_name" placeholder="客户姓名" @keyup.enter="searchHandle"></el-input>
                            </el-form-item>
                            <el-form-item label="订单创建时间" prop="created_at">
                              <el-date-picker
                                v-model="search_config.form.created_at"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                format="YYYY-MM-DD hh:mm:ss"
                                value-format="YYYY-MM-DD hh:mm:ss">
                              </el-date-picker>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button class="global-ml-10" @click="resetHandle">重置</el-button>
                                <el-button class="global-ml-10" type="success" @click="searchHandle">搜索</el-button>
                                <el-button class="global-ml-10" type="success" @click="exportHandle">导出</el-button>
                                <!-- <el-button class="global-ml-10" type="success" @click="searchHandle">创建订单</el-button> -->
                                <span class="hint-text">未支付订单请前往小程序完成支付</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </template>
            <div>
                <table-component :table="table">
                    <template #default="data">
                        <div v-if="data.item.prop == 'mo_type'">{{mo_type_config[data.scope.row[data.item.prop]] || '--'}}</div>
                        <el-tag v-else-if="data.item.prop == 'status'" :type="statusColor(data.scope.row[data.item.prop])">{{status_config[data.scope.row[data.item.prop]] || '--'}}</el-tag>
                        <div v-else-if="data.item.prop == 'control'">
                          <el-button @click="openOrderDetailPopup(data.scope.row)">查看详情</el-button>
                        </div>
                        <div v-else-if="data.item.prop == 'avatarUrl'">
                          <el-avatar :src="data.scope.row.user.avatarUrl" />
                        </div>
                        <div v-else-if="data.item.prop == 'nickName'">
                          <span>{{data.scope.row.user.nickName}}</span>
                        </div>
                        <div v-else>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
                <div class="pagination-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pagination.page"
                        :page-sizes="pagination.page_sizes"
                        :page-size="pagination.page_size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagination.total">
                    </el-pagination>
                </div>
            </div>
        </el-card>

        <!-- 订单详情 start -->
        <el-dialog
            :title="detail_config.title"
            v-model="detail_config.is_show"
            width="600px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div>
              <div class="detail-box" v-if="detail_config.detail">
                <div class="item">
                  <div class="title">订单信息</div>
                  <div class="box-list">
                    <div class="box-item">
                      <div class="left">订单类型：</div>
                      <div class="righ">量尺结果</div>
                    </div>
                    <div class="box-item">
                      <div class="left">订单状态：</div>
                      <div class="righ">{{status_config[detail_config.detail.status]}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">订单金额：</div>
                      <div class="righ">
                        <span>{{detail_config.detail.amount}}￥</span>
                        <span v-if="detail_config.detail.status == 1">（未支付）</span>
                        <span v-else-if="detail_config.detail.status == 2">（已支付）</span>
                      </div>
                    </div>
                    <div class="box-item">
                      <div class="left">订单编号：</div>
                      <div class="righ">{{detail_config.detail.mo_sn}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">量尺类型：</div>
                      <div class="righ">{{mo_type_config[detail_config.detail.mo_type]}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">空间数量：</div>
                      <div class="righ">{{detail_config.detail.spaces}}个</div>
                    </div>
                    <div class="box-item">
                      <div class="left">创建时间：</div>
                      <div class="righ">{{detail_config.detail.created_at}}</div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="title">客户信息</div>
                  <div class="box-list">
                    <div class="box-item">
                      <div class="left">客户姓名：</div>
                      <div class="righ">{{detail_config.detail.c_name}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">客户手机号：</div>
                      <div class="righ">{{detail_config.detail.c_mobile}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">客户地址：</div>
                      <div class="righ">{{detail_config.detail.c_address}}</div>
                    </div>
                    <div class="box-item">
                      <div class="left">期望量尺时间：</div>
                      <div class="righ">{{detail_config.detail.c_time}}</div>
                    </div>
                  </div>
                </div>
                <div class="item" v-if="detail_config.detail.status == 3">
                  <div class="title">量尺结果</div>
                  <div class="box-list">
                    <div class="box-item">
                      <div class="left active" @click="openResultDetailPopup(detail_config.detail)">点击查看</div>
                    </div>
                  </div>
                </div>
                <div class="download-box" v-if="detail_config.detail.zip_url">
                  <div class="down-text">
                    <span @click="copyHandle(detail_config.detail.zip_url)">复制下载链接</span>
                  </div>
                  <div class="down-box">
                    <input type="text" v-model="detail_config.email_url" placeholder="请输入您的邮箱">
                    <div class="down-btn" @click="sendEmail">发送邮箱</div>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeOrderDetailPopup">返回</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 订单详情 end -->

        <!-- 量尺完成内容 start -->
        <el-dialog
            :title="result_config.title"
            v-model="result_config.is_show"
            width="600px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div class="result-box" v-if="result_config.detail">
              <div class="list">
                <div>
                  <div class="item-title">订单信息</div>
                  <div class="item">
                    <div class="left">搬运信息备注</div>
                    <!-- <div class="right">{{result_config.detail.c__beizhu || '无'}}</div> -->
                    <div class="right">{{result_config.detail.c__xinxibeizhu || '无'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">是否需要转平台</div>
                    <div class="right">{{result_config.detail.c__shifouzhuanpingtai || '--'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">工程类型</div>
                    <div class="right">{{result_config.detail.c__gongchengleixing || '--'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">平路长度</div>
                    <div class="right">{{result_config.detail.c__pingtaichangdu || '--'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">斜坡长度</div>
                    <div class="right">{{result_config.detail.c__xiepochangdu || '--'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">电梯尺寸</div>
                    <div class="right">{{result_config.detail.c__diantichidu || '--'}}</div>
                  </div>
                </div>
                <div>
                  <div class="item-title">货车卸货区域照片</div>
                  <div class="imgs" v-if="result_config.detail.c__dahuochexiehuoquzhaopian_list && result_config.detail.c__dahuochexiehuoquzhaopian_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__dahuochexiehuoquzhaopian_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                  <div class="item">
                    <div class="left">小区可进几辆车</div>
                    <div class="right">{{result_config.detail.c__xiaoqunengjinjidunche || '--'}}</div>
                  </div>
                  <div class="item">
                    <div class="left">吊柜墙性质</div>
                    <div class="right">{{result_config.detail.c__diaoguiqiangtixingzhi}}</div>
                  </div>
                </div>
                <div>
                  <div class="item-title">主卧照片</div>
                  <div class="imgs" v-if="result_config.detail.c__fangjian5_list && result_config.detail.c__fangjian5_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__fangjian5_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">客卧照片</div>
                  <div class="imgs" v-if="result_config.detail.c__fangjian3_list && result_config.detail.c__fangjian3_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__fangjian3_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">儿童房照片</div>
                  <div class="imgs" v-if="result_config.detail.c__fangjian2_list && result_config.detail.c__fangjian2_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__fangjian2_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">厨房照片</div>
                  <div class="imgs" v-if="result_config.detail.c__chufang_list && result_config.detail.c__chufang_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__chufang_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">客厅照片</div>
                  <div class="imgs" v-if="result_config.detail.c__fangjian4_list && result_config.detail.c__fangjian4_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__fangjian4_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">卫生间照片</div>
                  <div class="imgs" v-if="result_config.detail.c__weishengjian_list && result_config.detail.c__weishengjian_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__weishengjian_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">工人房照片</div>
                  <div class="imgs" v-if="result_config.detail.c__gongrenfang_list && result_config.detail.c__gongrenfang_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__gongrenfang_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-title">其他房间照片</div>
                  <div class="imgs" v-if="result_config.detail.c__fangjian1_list && result_config.detail.c__fangjian1_list.length > 0">
                    <div class="imgitem" v-for="(item,index) of result_config.detail.c__fangjian1_list" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="item" :preview-src-list="[item]" fit="cover"></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeResultDetailPopup">返回</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 量尺完成内容 end -->

    </div>
</template>
<script>
import qs from 'qs'
import { ElMessage } from 'element-plus'
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  ref,
  inject,
  watch
} from "vue";
import {commonApi,platformApi} from '@/api'
import tableComponent from '@/components/table/index.vue'
export default defineComponent({
  name: "order-manage-page",
  components:{
    tableComponent
  },
  setup(props) {
    let formRef = ref(null)
    let dayjs = inject('dayjs')
    let pageData = reactive({
      photo_url:'https://img.vip.imocq.com/000-e01ca11a-9a48-4614-8831-2ddffe44fdcc',
      detail_config:{
        is_show:false,
        title:'订单详情',
        detail:null,
        email_url:''
      },
      result_config:{
        is_show:false,
        title:'量尺结果',
        detail:null
      },
      search_config:{
        form:{
          shops_name:'',
          mo_sn:'',
          mo_type:'0',
          status:'0',
          c_name:'',
          created_at:[]
        }
      },
      mo_type_config:{
        1:'初尺',
        2:'复尺'
      },
      status_config:{
        1:'待支付',
        2:'待量尺',
        3:'已完成',
        4:'已取消',
        5:'补差价',
        6:'待上门',
      },
      orderTypeOptions:[
        {
          value:'0',
          label:'全部'
        },
        {
          value:'1',
          label:'初尺'
        },
        {
          value:'2',
          label:'复尺'
        }
      ],
      orderStatusOptions:[
        {
          value:'0',
          label:'全部'
        },
        {
          value:'1',
          label:'待支付'
        },
        {
          value:'2',
          label:'待量尺'
        },
        {
          value:'3',
          label:'已完成'
        },
        {
          value:'4',
          label:'已取消'
        },
        {
          value:'5',
          label:'补差价'
        },
        {
          value:'6',
          label:'待上门'
        }
      ],
      table:{
        column_key:[
            {
                prop:'mo_sn',
                label:'订单编号'
            },
            {
                prop:'mo_type',
                label:'订单类型'
            },
            {
                prop:'status',
                label:'订单状态'
            },
            {
                prop:'c_name',
                label:'客户姓名'
            },
            {
                prop:'c_mobile',
                label:'客户手机号'
            },
            {
                prop:'c_address',
                label:'客户地址'
            },
            {
                prop:'s_name',
                label:'门店名称'
            },
            {
                prop:'f_name',
                label:'工厂名称'
            },
            {
                prop:'avatarUrl',
                label:'头像'
            },
            {
                prop:'nickName',
                label:'昵称'
            },
            {
                prop:'created_at',
                label:'订单创建时间'
            },
            {
                prop:'updated_at',
                label:'订单更新时间'
            },
            {
                prop:'control',
                label:'操作'
            },
        ],
        list:[]
      },
      pagination:{
        page:1,
        page_size:10,
        total:0,
        page_sizes:[10,50,100,200,500]
      },
    })

    watch(()=>pageData.detail_config.is_show,(newVal)=>{
      if(!newVal){
        pageData.detail_config.email_url = ''
      }
    })
    
    onMounted(() => {
      getList()
    });

    function getList(){
      let form = JSON.parse(JSON.stringify(pageData.search_config.form))
      let time_obj = {
        start_time:'',
        end_time:''
      }

      if(form.created_at && form.created_at.length>0){
        time_obj.start_time = form.created_at[0]
        time_obj.end_time = form.created_at[1]
      }

      delete form.created_at

      platformApi.measurementsList({
        ...form,
        ...time_obj,
        page:pageData.pagination.page,
        pageSize:pageData.pagination.page_size,
      }).then(res=>{
        let list = res.data.list
        for(let i in list){
          if(list[i].created_at){
              list[i].created_at = dayjs(list[i].created_at).format('YYYY-MM-DD hh:mm:ss')
          }
          if(list[i].updated_at){
              list[i].updated_at = dayjs(list[i].updated_at).format('YYYY-MM-DD hh:mm:ss')
          }
        }
        pageData.table.list = list
        pageData.pagination.total = res.data.total
      })
    }

    function sendEmail(){
      if(!pageData.detail_config.email_url.trim()){
        ElMessage.warning('请输入邮箱地址')
        return;
      }
      platformApi.sendEmail(qs.stringify({
        id:pageData.detail_config.detail.id,
        email:pageData.detail_config.email_url
      })).then(res=>{
        ElMessage.success('发送成功')
        pageData.detail_config.email_url = ''
      })
    }

    function statusColor(status){
      if(status == 1){
        return 'danger'
      }else if(status == 2 || status == 6){
        return 'warning'
      }else if(status == 3){
        return ''
      }else if(status == 4){
        return 'info'
      }else if(status == 5){
        return 'success'
      }else{
        return ''
      }
    }

    function searchHandle(){
      getList() 
    }

    function exportHandle(){
      let form = JSON.parse(JSON.stringify(pageData.search_config.form))
      let time_obj = {
        start_time:'',
        end_time:''
      }

      if(form.created_at && form.created_at.length>0){
        time_obj.start_time = form.created_at[0]
        time_obj.end_time = form.created_at[1]
      }

      delete form.created_at
      platformApi.getMeasurementsList({
        ...form,
        ...time_obj,
        page:pageData.pagination.page,
        pageSize:pageData.pagination.page_size,
        export:1
      }).then(res=>{
        
      })
    }

    function resetHandle(){
      formRef.value.resetFields()
      getList()
    }

    function handleSizeChange(page_size){
        pageData.pagination.page_size = page_size
        getList()
    }
    function handleCurrentChange(page){
        pageData.pagination.page = page
        getList()
    }

    function openOrderDetailPopup(item){
      commonApi.orderDetail({
        id:item.id
      }).then(res=>{
        if(res.data.created_at){
            res.data.created_at = dayjs(res.data.created_at).format('YYYY-MM-DD hh:mm:ss')
        }
        pageData.detail_config.detail = res.data
        pageData.detail_config.is_show = true
      })
    }

    function closeOrderDetailPopup(){
      pageData.detail_config.is_show = false
      pageData.detail_config.detail = null
    }

    function openResultDetailPopup(item){
      commonApi.mOrderDone({
        id:item.id
      }).then(res=>{
        let detail = res.data.data[0]
        
        if(detail.c__dahuochexiehuoquzhaopian){
          detail['c__dahuochexiehuoquzhaopian_list'] = detail.c__dahuochexiehuoquzhaopian.split('|')
          for(let i in detail.c__dahuochexiehuoquzhaopian_list){
            detail.c__dahuochexiehuoquzhaopian_list[i] = pageData.photo_url + detail.c__dahuochexiehuoquzhaopian_list[i]
          }
        }
        
        if(detail.c__fangjian5){
          detail['c__fangjian5_list'] = detail.c__fangjian5.split('|')
          for(let i in detail.c__fangjian5_list){
            detail.c__fangjian5_list[i] = pageData.photo_url + detail.c__fangjian5_list[i]
          }
        }
        
        if(detail.c__fangjian3){
          detail['c__fangjian3_list'] = detail.c__fangjian3.split('|')
          for(let i in detail.c__fangjian3_list){
            detail.c__fangjian3_list[i] = pageData.photo_url + detail.c__fangjian3_list[i]
          }
        }
        
        if(detail.c__fangjian2){
          detail['c__fangjian2_list'] = detail.c__fangjian2.split('|')
          for(let i in detail.c__fangjian2_list){
            detail.c__fangjian2_list[i] = pageData.photo_url + detail.c__fangjian2_list[i]
          }
        }
        
        if(detail.c__chufang){
          detail['c__chufang_list'] = detail.c__chufang.split('|')
          for(let i in detail.c__chufang_list){
            detail.c__chufang_list[i] = pageData.photo_url + detail.c__chufang_list[i]
          }
        }
        
        if(detail.c__fangjian4){
          detail['c__fangjian4_list'] = detail.c__fangjian4.split('|')
          for(let i in detail.c__fangjian4_list){
            detail.c__fangjian4_list[i] = pageData.photo_url + detail.c__fangjian4_list[i]
          }
        }
        
        if(detail.c__weishengjian){
          detail['c__weishengjian_list'] = detail.c__weishengjian.split('|')
          for(let i in detail.c__weishengjian_list){
            detail.c__weishengjian_list[i] = pageData.photo_url + detail.c__weishengjian_list[i]
          }
        }
        
        if(detail.c__gongrenfang){
          detail['c__gongrenfang_list'] = detail.c__gongrenfang.split('|')
          for(let i in detail.c__gongrenfang_list){
            detail.c__gongrenfang_list[i] = pageData.photo_url + detail.c__gongrenfang_list[i]
          }
        }
        
        if(detail.c__fangjian1){
          detail['c__fangjian1_list'] = detail.c__fangjian1.split('|')
          for(let i in detail.c__fangjian1_list){
            detail.c__fangjian1_list[i] = pageData.photo_url + detail.c__fangjian1_list[i]
          }
        }
        console.log('detail',detail)
        pageData.result_config.detail = detail
        pageData.result_config.is_show = true
      })
      
    }

    function closeResultDetailPopup(){
      pageData.result_config.is_show = false
      pageData.result_config.detail = null
    }

    function copyHandle(text){
      if(text){
        var copyinput = document.getElementById("copyinput");
        copyinput.value = text; // 修改文本框的内容
        copyinput.select(); // 选中文本
        document.execCommand("copy"); // 执行浏览器复制命令
        ElMessage.success('复制成功')
        copyinput.value = ''
      }
    }


    return {
      ...toRefs(pageData),
      formRef,
      searchHandle,
      exportHandle,
      resetHandle,
      openOrderDetailPopup,
      closeOrderDetailPopup,
      openResultDetailPopup,
      closeResultDetailPopup,
      handleSizeChange,
      handleCurrentChange,
      statusColor,
      sendEmail,
      copyHandle
    };
  },
});
</script>
<style lang="scss" scoped>
.copyinput{
  position: fixed;
  top:-2000px;
}
.download-box{
  margin: 10px 0;
  .down-text{
    text-align: right;
    color: #31C27D;
    font-size:15px;
    cursor: pointer;
  }
  .down-box{
    margin: 10px 0;
    height: 35px;
    display: flex;
    align-items: center;
    border:1px solid #31C27D;
    border-radius: 5px;
    overflow: hidden;
    input{
      padding-left: 7px;
      flex:1;
      height: 100%;
      border:none;
      outline: none;
    }
    .down-btn{
      width: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #31C27D;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.hint-text{
  color: red;
  margin-left: 10px;
}
.detail-box{
  .item{
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(230,230,230,1);
    &:last-child{
      margin-bottom: 0px;
      border-bottom:none;
    }
    .title{
      font-weight: bold;
      font-size: 16px;
    }
    .box-list{
      .box-item{
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        color: rgb(150,150,150);
        .active{
          color: #409eff;
          cursor: pointer;
        }
      }
    }
  }
}
.result-box{
  .list{
    margin-top: 13.5px;
    padding-bottom: 55px;
    .item-title{
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 23px;
    }
    .item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      .left{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .right{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .imgs{
      margin-top: 13px;
      .imgitem{
        display: inline-block;
        margin-right: 10px;
        width: 85px;
        height: 63px;
        overflow: hidden;
        background-color: rgb(211, 211, 211);
        margin-bottom: 5px;
        &:nth-child(3n){
          margin-right: 0;
        }
        image{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>